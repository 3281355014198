
import { defineComponent } from "vue";
import Generator from "@/components/reusable/GeneratorAddition.vue";

export default defineComponent({
  name: "own-building-addition-step-3",
  components: {
    Generator
  },
  props: {
    generatorRate: Number,
    generatorRatePublicId: String,
    policyId: String
  },
  data() {
    return {
      totalPremium: 0.0 as number
    };
  }
});
